import { render, staticRenderFns } from "./LocationType.vue?vue&type=template&id=3595fc1c"
import script from "./LocationType.vue?vue&type=script&lang=ts&setup=true"
export * from "./LocationType.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3595fc1c')) {
      api.createRecord('3595fc1c', component.options)
    } else {
      api.reload('3595fc1c', component.options)
    }
    module.hot.accept("./LocationType.vue?vue&type=template&id=3595fc1c", function () {
      api.rerender('3595fc1c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/masterdata/LocationType.vue"
export default component.exports